body {
    margin: 0;
    background-color: #1e1e1e;
    overflow: hidden;
}

canvas {
    width: 100%;
    height: 100vh;
    position: absolute;
}

.App {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

h1 {
    color: white;
    font-weight: lighter;
    font-size: 24px;
}

.logo {
    width: 50px;
    fill: white;
}
